let awsRegion = 'us-east-2';

export default {
 api_urls: {
   reporting: 'https://k73lt3tge3.execute-api.us-east-2.amazonaws.com/api',
   geojson: 'https://esdyz0s9q0.execute-api.us-east-2.amazonaws.com/api',
   overlays: 'https://679z9unujd.execute-api.us-east-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.testing.vale.forwoodsafety.com',
 forwood_id_url: 'https://id.testing.vale.forwoodsafety.com?redirect_uri=https://geoeditor.testing.vale.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.testing.vale.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-east-2_HvAtul4tj',
   userPoolWebClientId: '7p0hceu1hnu07l0tep01kdm5uf',
   cookieStorage: {
     domain: '.testing.vale.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'True',
 env: 'testing'
};
